.table {

    &.table-theme {
        thead {
            background-color: rgba(0, 149, 223, 0.5);
        }

        tbody {
            tr {
                td {
                    font-size: 1rem;
                    vertical-align: middle;
                }

                &:last-child {
                    border-bottom: transparent;
                }
            }
        }
    }

    thead {

        tr {

            th {
                font-size: 1rem;
            }
        }
    }

    tbody {}
}

.table-actions {
    a {
        font-size: 1.3rem;
    }
}


.table> :not(:first-child) {
    border-top: 0;
}


.va-middle {

    td,
    th {
        vertical-align: middle;
    }
}

.table-add-sale {
    .col-input {
        max-width: 70px;
    }
}

.date-sheetpage {
    table {
        background-color: #fff !important;
        padding: 10px !important;

        thead {
            width: 100% !important;
            background-color: rgba(0, 149, 223, 0.5);

            th {
                font-size: 1rem;
                padding: .5rem .5rem;
                border-bottom-width: 1px;
                border-color: #dbdedf;
            }
        }

        tbody {
            width: 100% !important;

            tr {
                padding: .5rem .5rem;
                border-bottom-width: 1px;
                border-color: #dbdedf;

                &:nth-of-type(odd) {
                    background: #eef0f1;
                }

                td {
                    font-size: 1rem;
                    padding: .5rem .5rem;
                    vertical-align: middle;
                }
                &:hover{
                    background: #eef0f1;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .card {
        overflow: scroll;
    }

    .schedule-mobile {
        overflow: scroll;
        width: 45rem;
    }

    .table {
        /* transform: rotateZ(90deg);
        margin: 35px 0px;
        width: 100%;*/

        &.table-theme {
            thead {
                background-color: rgba(0, 149, 223, 0.5);
            }

            tbody {
                tr {
                    td {
                        font-size: .8rem;
                    }
                }

                > :not(:first-child) {
                    font-size: 3rem;
                }
            }
        }

        thead {

            tr {

                th {
                    font-size: .8rem;
                }
            }
        }
    }
}