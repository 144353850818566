.syllabus {
    gap: 40px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
        display: list-item;
        text-align: -webkit-match-parent;

        .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 4px solid $primary;
            border-radius: 50%;
            height: 265px;
            width: 265px;
            position: relative;

            img {
                position: absolute;
                bottom: -12px;
                right: -9px;
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: white;
                height: 228.5px;
                width: 228.5px;
                border-radius: 50%;
                z-index: 2;

                h4 {
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0;
                    color: $primary;
                }

                h3 {
                    font-size: 36px;
                    font-weight: bold;
                    margin: 0;
                    color: $primary;
                }

                .class {
                    color: $themeLight;
                    font-size: 24px;
                }

                a {
                    border-radius: 28px;
                                        margin-top: 10px;

                    span {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 767px) {
    
}
