.faculty-card {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px 10px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin-bottom: 17px;
    padding: 23px;
    width: 416px;


    h2 {
        margin: 0;
        font-size: 1.2rem;
        font-weight: 600;
        color: black;
        margin-bottom: 10px;
        position: relative;

        &::after {
            content: "";
            display: block;
            width: 20%;
            height: 2px;
            background-color: black;
            position: absolute;
            top: 26px;
        }
    }

    .content {
        text-align: left;
        margin: 25px 0px 20px 0px;

        p {
            margin: 0;
        }
    }

    .experience {
        display: flex;
        justify-content: flex-start;
        text-align: center;
        align-items: baseline;

        span {
            width: 85px;
            height: 35px;
            background-color: $secondary;
            border-radius: 6px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: white;
        }

        p {
            margin-left: 10px;
            color: #7f8083;
        }
    }
}

.thk-you {
    font-size: 1.3rem;

    h3 {
        font-weight: bold;
    }
}

@media screen and (max-width: 767px) {
    .faculty-card {
        h2 {
            &::after {
                top: 30px;
            }
        }
    }
}