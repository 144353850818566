.events-card {
    border-radius: 0.625rem;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background-color: #FFF !important;
    width: 25.25rem;
    min-height: 25.25rem;
    color: black;
    overflow: hidden;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);

    img {
        animation-name: zoom;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }

    img {
        width: 25.25rem;
        height: 215px;

        @keyframes zoom {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.2);
            }

            100% {
                transform: scale(1);
            }
        }
    }

    .detail {
        background-color: white;
        padding: 1rem;
        width: 25.25rem;
        height: 11.5625rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        .card-detail {
            .date {
                padding-bottom: 2px;
                align-items: center;
                display: flex;

                span {
                    color: black;
                    font-weight: 500;
                }

                i {
                    margin-right: 10px;
                    font-size: 17px;
                    color: $secondary;
                }
            }

            h5 {
                color: black;
                font-size: 1.25rem;
                font-weight: 600;
                line-height: 140.523%;

            }

            p {
                color: rgba(0, 0, 0, 0.60);
                font-size: 0.875rem;
                line-height: 137.023%;
            }
        }


        a {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            color: $secondary;
            padding-top: 0.9rem;
            border-top: 1px solid rgba(0, 0, 0, 0.10);

            .ri-arrow-right-line {
                font-size: 20px;
            }

            &:hover {
                animation-name: fade-in;
                animation-duration: 0.5s;
            }

            @keyframes fade-in {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }


}


.events-img {
    width: 100%;
    height: 215px;
}

.events-detail-card {
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;

    .event-body {

        h1 {
            font-size: 2.2rem;
            font-weight: bold;
        }

        p {
            font-size: 14px;
            line-height: 19px;
        }
    }

    .date {
        border-top: 1px solid rgba(0, 0, 0, 0.10);
        padding-top: 1rem;
        align-items: center;
        display: flex;

        span {
            color: black;
            font-weight: 500;
        }

        i {
            font-size: 17px;
            margin-right: 10px;
            color: $secondary;
        }
    }
}

@media screen and (max-width : 767px) {


    .events-img {
        width: 25.25rem;
        height: 200px;
    }

    .events-card {
        width: 22.25rem;
        min-height: 22.25rem;
        margin-bottom: 15px;
    }
}