.pagination {
    --bs-pagination-bg: #ffffff;
    --bs-pagination-color: $secondary;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: transparant;
    --bs-pagination-hover-color: $secondary;
    --bs-pagination-hover-bg: #e9ecef;
    --bs-pagination-hover-border-color: transparant;
    --bs-pagination-focus-color: white;
    --bs-pagination-focus-bg: #007efd;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-pagination-active-color: #000000;
    --bs-pagination-active-bg: rgb(255, 255, 255);
    --bs-pagination-active-border-color: #0d6efd;
    --bs-pagination-disabled-color: #fff;
    --bs-pagination-disabled-bg: #0095DF;
    --bs-pagination-disabled-border-color: #007fff;

    .next {
        padding: 6px 24px;
    }
}

.page-item {
    .page-link {
        border-radius: 50%;
        margin: 3px;
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 10px;

        &:focus {
            background: $secondary;
            color: white;
        }
    }

    &:hover {
        color: $secondary;
    }

    &:first-child {
        .page-link {
            border-radius: 50%;
            background: $secondary;
            color: white;
            margin-left: 0px;
            border-top-left-radius: 0;
        }
    }
    &:last-child {
        .page-link {
            border-radius: 50%;
            background: $secondary;
            color: white;
            margin-left: 0px;
            border-bottom-right-radius: 0;
        }
    }
}