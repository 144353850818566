.admin-header {
    background-color: #FFF;
    padding: .6rem;

    .brand {
        width: 240px;
    }

    .navbar-general {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            &.user-logged-in {
                #dropdownUserLoggedIn {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    outline: none;
                    border: transparent;

                    &::after {
                        display: none;
                    }

                    .thumb {
                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                        }
                    }

                    .info {
                        padding-left: .6rem;
                        line-height: 1rem;

                        .user_name {
                            display: block;
                            font-size: 1rem;
                            color: $secondary;
                            font-weight: 600;
                        }

                        .role {
                            font-size: .8rem;
                        }
                    }
                }

                .dropdown-menu {
                    border: none;
                    top: 12px !important;
                    border-radius: 0;

                    li {
                        .dropdown-item {
                            display: flex;
                            align-items: center;

                            i {
                                margin-right: .6rem;
                            }
                        }
                    }
                }
            }

            &.admin-toggle-side-menu {
                padding: 0 1rem;

                a {
                    color: $secondary;
                    font-size: 1.7rem;
                }
            }

            .nav {
                .nav-item {
                    .nav-link {
                        color: $primary;

                        &:hover {
                            color: $secondary;
                        }
                    }
                }
            }
        }
    }
}

.navbar-module {
    background-color: $primary;
    padding: 0;

    .navbar-nav {
        .nav-item {
            .nav-link {
                color: #FFF;
                padding: 1rem 1.3rem;
                font-size: .9rem;
                border-right: 1px solid rgba(255, 255, 255, 0.2);

                &:hover,
                &:focus {
                    background-color: $secondary;
                }
            }

            &:last-child {
                .nav-link {
                    border-right: none;
                }
            }
        }
    }
}

.admin-sidebar {
    .size-3 {
        width: 300px;
    }

    .offcanvas-main-menu {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            a {
                display: flex;
                align-items: center;
                padding: .8rem 1rem;
                font-size: .9rem;
                border-radius: .8rem;

                span {
                    color: #000;
                }

                &:hover {
                    @include bgSecondary(0.1);

                    span {
                        color: #0095DF;
                    }
                }

                img {
                    width: 26px;
                    margin-right: 1rem;
                }
            }
        }
    }
}

.admin-footer {
    background-color: $lightGray;
    background-image: none;
    position: fixed;
    padding: .4rem 0;
    bottom: 0;
    width: 100%;
    font-size: .8rem;

    .copyright {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 1rem 0;
        margin-top: 1rem;

        .row {
            align-items: center;
        }

        .footer-social {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            li {
                margin-left: 1rem;
                font-size: 1.5rem;

                a {
                    @include textBlack(0.5);
                }
            }
        }
    }
}

.upload-box {
    display: flex;
    align-items: center;
    justify-content: center;





    min-height: 200px;
    color: rgb(45, 45, 45);
    padding: 40px;
    flex-direction: column;

    label {
        text-align: center;
    }

    h4 {
        font-weight: 500;
        font-size: 16px;
    }

    p {
        font-size: 12px;
        margin-bottom: 20px;
        color: rgb(181, 168, 168);
    }

    input {
        position: absolute;
        opacity: 0;
        left: 0;
    }
}


.upload-img-card {
    border-radius: 12px;
    border: 2px dashed $primary;
    padding: 0;
    overflow: hidden;
   
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);

    .upload-box {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(45, 45, 45);
        padding: 0;
        flex-direction: column;


        input {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        .thumnail {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            width: 95%;

            svg {
                width: 20px;
                margin-bottom: 10px;
            }

            p {
                margin: 0;
            }
        }
    }


    .delete {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #FFF;
        display: inline-flex;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        left: 15px;

        svg {
            stroke: $primary;
            width: 20px;
        }
    }

    &.cursor_none {
        cursor: not-allowed;

        .thumnail,
        p {
            cursor: not-allowed;
        }
    }
}



@media screen and (max-width: 767px) {
    .admin-header {
        .navbar-general {
            li {
                &.user-logged-in {
                    #dropdownUserLoggedIn {

                        .info {
                            display: none;
                        }


                    }
                }
            }
        }
    }

    .call-to-action {
        padding: 2.75rem;
        text-align: center;

        .cta-button {
            text-align: center;
        }
    }

}