.contact {
    border: 1px solid $secondary;
    align-items: center;
    text-align: center;
    padding: 50px 30px 47px 30px;
    border-radius: 5px;
    margin: 0;
    min-height: 310px;

    i {
        font-size: 50px;
        color: $secondary;

    }

    h5 {
        font-size: 22px;
        margin-bottom: 25px;
    }

    .number {
        p {
            font-size: 15px;
            color: $gray;
            line-height: 16px;
        }
    }

}

.map {
    iframe {
        border-radius: 0.5rem;
    }
}

@media screen and (max-width : 767px) {
    .contact {
        margin-bottom: 17px;

    }
}