.admin-page-head {
	padding-top: 0.8rem;
	padding-bottom: 1rem;
	margin-bottom: 1rem;

	.page-title {
		font-weight: 800;
		font-size: 1.8rem;
		color: $secondary;
		line-height: 35px;
	}

	.breadcrumb {
		margin: 0;

		a {
			color: #293333;
		}
	}

	.page-right-actions {
		display: flex;
		justify-content: flex-end;

		.button {
			margin-left: 1rem;
		}
	}
}


@media screen and (max-width : 767px) {
	.admin-page-head {
		.page-right-actions {
			justify-content: center;
			gap: 10px;
			margin-top: 1rem;

			.button {
				display: flex;
				justify-content: space-between;
				text-align: center;
				font-size: .9rem;
				margin-left: 0;
			}

		}
	}

}