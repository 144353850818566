.site-header {
  background-color: #fff;
  transition: background 0.5s;

  &.sticky {
    position: fixed;
    padding: 0;
    z-index: 3;
    background-color: $primary;

    .navbar-brand {
      img {
        max-width: 150px;
      }
    }
  }

  .topbar {
    background-color: $primary;

    .top-contact {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;

      li {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0.5rem 0.8rem;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        font-size: 0.9rem;

        &:last-child {
          border-right: 0;
        }

        i {
          margin-right: 0.4rem;
          font-size: 1.2rem;
        }
      }
    }

    .top-social {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;

      .about-us,
      .contact-us {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
      }

      li {
        display: flex;
        align-items: center;
        padding: 0.5rem 0.7rem;
        font-size: 0.9rem;

        a {
          color: #fff;
          font-size: 1rem;
          display: flex;

          span {
            i {
              font-size: 1.4rem;
            }
          }

          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
    .site-header {
        .topbar {
            background-color: $secondary;

            .top-contact {
                justify-content: center;
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);

            }

            .top-social {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                li {
                    align-items: center;
                    padding: 8px;

                    a {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}


