.dashboard {
	margin-top: 3rem;

}

.dashboard-buttons {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	li {
		flex-basis: 18%;
		margin: .5rem;

		a {
			background: #FFFFFF;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 150px;
			border: 1px solid transparent;
			color: $primary;

			&:hover {
				border-color: $secondary;
				color: $secondary;
			}

			img {
				margin-bottom: 1rem;
			}

			span {
				font-weight: 600;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.dashboard-buttons {
		li {
			flex-basis: auto;
			width: 150.675px;
		}
	}
}