.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 0px;
  min-width: 300px;

  img {
    width: 230px;
    height: 230px;
    border-radius: 50%;
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 0.9rem;
  }

  h6 {
    margin: 0;
    color: $primary;
    font-weight: bold;
    font-size: 1.2rem;
  }

  span {
    color: $themeLight;
  }
}

@media screen and (max-width: 767px) {
  .user {
    margin: 5px 0px;
  }
}