.page-head {
  background-image: url("../images/banner.jpg");
  min-height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  h3 {
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
  }

  .breadcrumb {
    li {
      color: rgba($color: #fff, $alpha: 0.6);

      a {
        color: rgba($color: #fff, $alpha: 0.9);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .page-head {
    h3 {
      font-size: 1.5rem;
    }
  }
}
