.simple-section {
    padding: 70px;

    .section-title {

        h3 {
            font-weight: 800;
            font-size: 40px;
        }

        h4 {
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 134.02%;
            color: $secondary;
        }
    }
}



.responsive {
    gap: 40px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1.8rem;

    li {
        display: list-item;
        text-align: -webkit-match-parent;
    }
}

.content-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.section-detail {
    p {
        font-family: $font-teacher;
        padding-top: 10px;
        font-weight: 300;
        font-size: 1rem;
        line-height: 20px;
    }
}

.dps {
    margin-left: 20%;
}

.merit-card {
    padding: 56px;
    background-color: white;
    border-radius: 8px;

    img {
        width: 230px;
    }

    .form-group {
        background: #f0f2f5;
        padding: 38px 28px;
        border-radius: 10px;
    }

    .content {
        display: flex;
        gap: 10px;
    }


}

@media screen and (max-width: 767px) {

    .merit-card {
        padding: 35px 15px 15px 15px;

        img {
            width: 130px;
        }

        .form-group {
            padding: 14px 28px;
        }

        .content {
            flex-direction: column;
        }

    }
}




.scrollbar {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: .6rem;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, .1);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(182, 182, 182);
    }
}

.inline-action {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0px;


    li {
        padding: 0px 4px;
        list-style: none !important;

        button,
        a {
            border-radius: 50% !important;
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            transition: 0.5s;
            font-size: 13px;

            span,
            i {
                font-size: 13px;
            }
        }

        .primary {
            border: 1px solid transparent;
            background: $primary;
            color: #ffffff;

            &:hover {
                border: 1px solid $primary;
                background: transparent;
                color: $primary;
            }
        }

        .red {
            border: 1px solid transparent;
            background: red;
            color: #ffffff;

            &:hover {
                border: 1px solid red;
                background: transparent;
                color: red;
            }
        }

        .black {
            border: 1px solid transparent;
            background: black;
            color: #ffffff;

            &:hover {
                border: 1px solid black;
                background: transparent;
                color: black;
            }
        }

        .gray {
            border: 1px solid transparent;
            background: $gray;
            color: #000000;

            &:hover {
                border: 1px solid $gray;
                background: transparent;
                color: $gray;
            }
        }
    }
}

.choose-photo {
    label {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: $radius;
        border: 1px dashed rgba(0, 0, 0, 0.4);
        height: 300px;
        cursor: pointer;

        i {
            font-size: 5rem;
            color: rgba(0, 0, 0, 0.4);
        }

        input {
            position: absolute;
            opacity: 0;
        }
    }
}

.modal-theme {

    &.transparent {
        color: #000000;

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button[aria-label="Close"] {
                display: none;
            }

            .btn-close {
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                width: auto;
                height: auto;
                padding: .25em .25em;
                color: #000;
                background: none;
                border: 0;
                border-radius: none;
                opacity: 1;

                svg {
                    width: 30px;
                    color: $secondary;
                }
            }

        }

        .modal-body {
            background: #ffffff;
        }

        .modal-footer {
            padding: 1.2rem 2rem;
            background: #ffffff;
        }
    }

}

.student-card {
    display: flex;
    align-items: center;
    gap: 10px;

    .card-header {

        background: #ffffff;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        min-width: 250px;

        .profile {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            background-color: #f0f2f5;
            overflow: hidden;

            img {
                width: 100%;

            }
        }

        .user-name {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: center;

            h1 {
                color: $primary;
                font-size: 1rem;
                font-weight: 700;
                margin: 0;
            }

            p {
                color: #909090;
                font-size: .8rem;
                margin: 0;
            }
        }
    }

    .menu {

        background: #ffffff;
        padding: 10px 20px;
        color: #000;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: auto;
        min-height: 215px;

        .outline {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px dashed #d7d7d7;
            width: 100%;
            padding: 4px 0;

            b {
                color: #000;
            }
        }
    }

    .profile-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        list-style: none;
        margin: 25px 0;
        gap: 10px 0;

        li {

            &:nth-child(odd) {
                color: black;
                font-weight: bold;
                min-width: 20%;
            }

            &:nth-child(even) {
                color: gray;
                font-weight: 500;
                min-width: 30%;
            }


        }
    }

}

.fee-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        &:last-child {
            label {
                font-weight: 600;
            }
        }
    }



}

.captcha-verify-row {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

}

.verify {
    display: flex;
    align-items: center;
    gap: 10px;


    .Character {
        background: #000000;
        padding: 5px 20px;
        color: #ffffff;
        user-select: none;
        min-width: 110px;
        border-radius: 5px;
        font-size: 21px;
    }

    .form-group {
        width: 150px;
    }
}

.task {
    height: 18px;
    width: 100%;
    overflow: hidden;
    position: relative;

    .progress-bar-striped {
        background-image: linear-gradient(45deg,
                rgba(255, 255, 255, 0.15) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.15) 50%,
                rgba(255, 255, 255, 0.15) 75%,
                transparent 75%,
                transparent);
        background-size: 40px 40px;
        animation: wave 2s linear infinite;
    }
}

@keyframes wave {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 40px 0;
    }
}

@media screen and (max-width : 767px) {
    .school {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .simple-section {
        padding: 70px 8px;

        .section-title {
            text-align: center;
            padding-bottom: 1px;

            h3 {
                padding-bottom: 18px;
                font-size: 27px;

            }
        }

        .photo {
            margin-top: 35px;
        }
    }

    .section-detail {
        text-align: center;
    }

    .dps {
        margin-left: 0%;
    }

    .captcha-verify-row {
        flex-direction: column;

        .verify {
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            .Character {
                width: 74%;
                text-align: center;
            }

            a {
                width: 20%;
            }

            .form-group {
                width: 100%;
            }
        }

        button {
            width: 100%;
        }

    }

    .fee-list {
        background: rgb(0 0 0 / 3%);
        border-radius: 10px;
        flex-direction: column;
        padding: 12px;
    }

    .student-card {
        flex-direction: column;

        .card-body,.card-header {
            width: 100%;
        }

        .menu {


            .outline {}
        }

        .profile-list {}

    }


}