.bog-member {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 55px 20px 27px 20px;
  position: relative;
  min-height: 208px;
  margin: 23px 0px;
  width: 305px;

  .img-wrap {
    position: absolute;
    top: -85px;
    left: 20px;
    width: 130px;
    height: 130px;
    border: 4px solid $secondary;
    border-radius: 50%;
    overflow: hidden;
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 0.9rem;
  }

  h3 {
    margin: 1px 0 0.5rem 0;
    color: $primary;
    font-weight: 500;
    font-size: 1.2rem;
    max-width: 197px;
  }

  span {
    color: $themeLight;
  }
}

.bog-responsive {
  gap: 50px 25px !important;
}

@media screen and (max-width: 767px) {
  .bog-member {
    margin: 57px 0px;
  }

  .bog-responsive {
    gap: 0px 40px !important;
  }
}