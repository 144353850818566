.wing-card {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
   
    overflow: hidden;
    position: relative;
    text-align: center;

    &:hover .wing-text {
        height: 221px;
        transition: all 0.5s ease-in-out;
    }

    &:hover img {
        transition: all 0.5s ease-in-out;
        transform: scale(1.1);
    }

    img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .wing-text {
        flex: 1 1 auto;
        padding: 1rem;
        text-align: center;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: white;
        height: 81px;

        span {
            color: black;
            font-family: 'Roboto Slab';
        }

        h3 {
            margin-top: 0;
            margin-bottom: 0.5rem;
        }

        p {
            margin: 0;
            overflow: hidden;
        }
    }
}

.tree {
    width: 100%;
    background-color: #1B2034;
    background-image: url("../images/Introduction/tree.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.white-box {
    background-color: white;
    border-radius: 10px;
    padding: 16px;
    width: 25%;
    text-align: center;
    margin: auto;
    margin-top: 25px;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);

    p {
        font-weight: 700;
        font-size: 20px;
        margin: 0;
        color: $secondary;
    }

    span {
        font-weight: 400;
        font-size: 17px;
        color: $gray;
    }
}
.semester {
    padding: 0.4rem 0;

    p {
        font-weight: 700;
        font-size: 30px;
        margin: 0;
    }

    span {
        font-weight: 400;
        font-size: 17px;
        color: $secondary;
    }
}
.schedule-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);

    h5 {
        font-weight: 700;
        font-size: 19px;
        margin: 14px 0px 0px 0px;
    }

    span {
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.3);
    }
}

