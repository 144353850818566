.child {
    padding: 92px 60px;

    h2 {
        color: white;
        font-weight: 800;
        font-size: 38px;
        line-height: 45px;
    }
}


.friend {
    background: linear-gradient(90deg, #003399 0%, #0095DF 100%);
    border-radius: 10px;
}

@media screen and (max-width : 767px) {
    .child {
        padding: 45px 52px;

        h2 {
            padding-bottom: 18px;
        }
    }
}