.massage-container {
    background-color: $primary;

 

    .row {

        .offset-md-4 {
            .massage-detail {
                padding: 100px;
                background-color: white;
                border-radius: 10px;
                position: relative;
                margin: 90px 0;
                z-index: 2;

                .profile {
                    display: none;
                    border-radius: 10px;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }

                h1 {
                    font-weight: bolder;
                    font-size: 22px;
                    line-height: 146.02%;
                }

                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 146.02%;
                    color: $themeLight;
                }

                h3 {
                    font-weight: bold;
                    font-size: 17px;
                    line-height: 146.02%;
                    color: $primary;
                }

                span {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 135.52%;
                    color: rgba(41, 51, 51, 0.7);

                }
            }


            >.profile {
                position: absolute;
                top: 175px;
                left: 175px;
                z-index: 2;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                width: 325px;
                height: 325px;

                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: -30px;
                    left: -30px;
                    border-radius: 10px;
                    border: 1px solid $secondary;
                    width: 325px;
                    height: 325px;
                    overflow: hidden;
                    z-index: -1;

                }
            }

            .dots {
                z-index: 1;
                position: absolute;
                top: 23px;
                right: 157px;
            }
        }
    }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 3rem;
    height: 3rem;
    margin-right: 38px;
}



@media screen and (max-width: 1399px) {

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      display: none;
    }

   
    .massage-container {
        .row {
            .offset-md-4 {
                width: 100% !important;
                margin-left: 0%;

                .massage-detail {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    padding: 41px;
                    padding-top: 20px;

                    .profile {
                        display: block;
                        margin: auto;
                        margin-bottom: 20px;

                        img {
                            width: 15rem;
                        }
                    }
                }



                >.profile {
                    display: none;

                }

                .dots {
                    top: -56px;
                    right: -7px;
                }
            }
        }

    }
}