.result {
    .results-img {
        overflow: hidden;
        border-radius: 31px;
        border: 4px solid #ff4689;

        img {
            border-radius: 16px;
            transform: scale(1.1);
        }
    }

    .section-detail {
        background-color: #ff4689;
        color: white;
        border-radius: 10px;
        padding: 18px;
    }
}


@media screen and (max-width : 767px) {
    .results-img {
        padding-bottom: 50px;
    }
}
