//colors
$primary: #003970;
$secondary: #0095DF;
$gray: #333333;
$lite:#F0F2F5;
$litegray: #c9cacc;
$themeLight: rgba(41, 51, 51, 0.7);
$black: #000000;
$liteblack:#F2F2F2;
$lightGray: #F8F8F8;

$green: rgb(97, 229, 156);

$red: #E21213;

$purple: #7F56D9;
$litepurple: #F6F7FF;

$blue: #165397;

$orange: #FBA628;
$liteorange: #fff7ea;

$darkgreen: #35C5BE;
$litegreen: #F6FEFE;










$color-petrol: #549B8C;
$color-diesel: #BD9B16;

$dark: #212121;
$light: #efefef;
$color: #b3e5fc;


// fonts

$font-primary: 'Inter', sans-serif;
$font-secondary: 'Open Sans', sans-serif;
$font-teacher: 'Roboto Slab', serif;
$font-heading: 'Kaushan Script', cursive;


// general

$radius: .4rem;


body{
    background-color: $lite;
    font-family: $font-primary;
}


ul {
    padding-left: 0;
}
a{
    text-decoration: none;
}