footer {
    background-image: url("../images/images/footer-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top:110px;

    .help {
        background-image: url("../images/images/ctabg.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position:relative;
        margin-top:-120px;
        .footer-contact {
            padding: 107px;

            h3 {
                color: $primary;
                font-weight: bold;
                margin: 0;
                margin-bottom: 10px;
            }

            p {
                color: #c3c3c3;
                margin-bottom: 10px;
            }

            h5 {
                color: #303030;
                margin: 0;
            }
        }
    }

    .border-line {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        padding-bottom: 50px;

        .Quick {
            line-height: 25px;

            h4 {
                color: white;
                font-weight: bold;
                font-size: 20px;
                padding: 50px 0px 30px 0px;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;


                li {
                    a {
                        color: white;
                    }


                    a {
                        text-decoration: none;
                        color: white;
                        line-height: 34px;
                        font-size: 16px;

                        i {
                            width: 30px;
                            height: 50px;
                            font-size: 20px;
                            font: bold;
                            padding: 10px;
                            background-color: gainsboro;
                        }
                    }
                }
            }
        }
    }
}

.Right {
    background-color: $themeLight;
}

@media screen and (max-width : 767px) {
    footer {
        margin-top: 0;

        .help {
            background-image: none;
            padding: 20px;
            background-color: white;
            margin-top: 94px;
            border-radius: 8px;

            .footer-contact {
                padding: 10px 0 20px 0px;

                h3 {
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 10px;
                }

                h5 {
                    font-size: 1.1rem;
                }
            }
        }

        .Quick {
            h4 {
                padding: 46px 0px 12px 0px;
            }

            .logo-dark {
                padding-top: 1%;
                padding-right: 0px;
            }
        }
    }
}
