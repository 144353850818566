.registration-card {
    background: #ffffff;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 56px;

    h3 {
        font-weight: 600;
        font-size: 26px;
        color: #003970;
        margin: 0;
    }

    p {
        font-size: 15px;
        color: black;
        margin: 0;

    }

    .result-card {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 43px;
        position: relative;

        h5 {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.8);
        }

        input {
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 10px;
            min-height: 45px;

            &:focus {
                outline: none;
                border: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
}


@media screen and (max-width: 767px) {

    .registration-card {
        padding: 20px;

        .result-card {
            padding: 22px 10px;
        }
    } 
}

