.hero-video {
    width: 100%;
    height: auto;
   
}
.hero-text {
    padding: 20px;
    text-align: center;
    width: 34%;
    /*background-color: #ffeff2;*/
    background-color: white;
    margin: auto;
    position: relative;
    top: -137px;
    right: 0;
    border-radius: 15px;

    h4 {
        font-weight: 500;
        font-size: 20px;
        color: $secondary;
        font-weight: bold;
    }

    h1 {
        font-weight: 800;
        font-size: 27px;
        text-transform: uppercase;
    }

    p {
        font-weight: 300;
        font-size: 15px;
    }

    h2 {
        font-size: 24px;

        span {
            color: $secondary;
        }
    }
}




@media screen and (max-width : 767px) {


    .hero-text {
        width: 93%;
        padding: 10px;
        top: -23px;

        h4 {
            font-weight: bold;
            font-size: 21px;
        }

        h1 {
            font-weight: bold;
            font-size: 20px;
        }

        p {
            font-size: 15px;
        }
    }
    }

