.home-work {
    background: #FFFFFF;
    box-shadow: 0px 3px 9px rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 15px 0;
    text-align: center;

    h2 {
        font-weight: 700;
        font-size: 30px;
        color: $secondary;
    }

    h5 {
        font-weight: 500;
        font-size: 20px;
    }

    a {
        justify-content: center;

        i {
            margin-left: 10px;
        }
    }
}
