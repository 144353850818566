.button {
  display: inline-block;
  vertical-align: middle;
  border: none;
  text-align: center;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 10px;
  border-radius: 8px;

  &.hover-on-dark {
    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }

  &:disabled {
    opacity: .7;
  }
}

.button-long {
  min-width: 130px;
}

.button-dark {
  background-color: $black;
  color: #fff;

  &:hover {
    background-color: transparent;
    border-color: $black;
    color: $black;
  }
}

.button-icon {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  padding: 0.6rem 0.6rem 0.6rem 1.3rem;
  white-space: nowrap;
  align-items: center;
  border-radius: 8px;
  gap: 10px;

  span {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
  }

  &.icon-right {
    span {
      margin-left: 0.8rem;
    }
  }

  &.icon-left {
    flex-direction: row-reverse;
    justify-content: center;
    padding: 0.6rem 1rem 0.6rem 1rem;

    span,
    i {
      margin-left: 0;
      margin-right: 0.4rem;
    }
  }

  &.button-dark {
    span {
      background-color: $primary;
      color: $black;
    }
  }
}

.button-white {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 20px #0000000b;
  color: $primary;

  &:hover {
    background-color: #f1f1f1;
  }
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.button-primary {
  background-color: $primary;
  color: #fff;

  &:hover {
    background-color: transparent;
    border-color: $primary;
    color: $primary;
    opacity: 1;
  }
}

.button-secondary {
  background-color: $secondary;
  color: #fff;
  font-weight: 600;
  border: 1px solid transparent;
  border-color: transparent;

  span {
    font-size: 1.4rem;

  }

  &:hover,
  &:focus {
    background-color: transparent;

    border-color: $secondary;
    color: $secondary;
    opacity: 1;
  }
}

.button-outline-secondary {
  border-color: $secondary;
  color: $secondary;
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: $secondary;
    border: 1px solid $secondary;
  }
}

.button-outline-primary {
  border-color: $primary;
  color: $primary;
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: $primary;
  }
}

.button-fb {
  background-color: #3c5a9a;
  color: #fff;

  font-weight: bold;

  &:hover {
    background-color: transparent;
    border-color: #3c5a9a;
    color: #3c5a9a;
    opacity: 1;
  }
}

.button-success {
  background-color: $green;
  color: #fff;

  &:hover {
    color: #fff;
    opacity: 0.8;
  }
}

.button-danger {
  background-color: $red;
  color: #fff;

  &:hover {
    color: #fff;
    opacity: 0.8;
  }
}

.button-round {
  border-radius: $radius;
}

.button-block {
  width: 100%;
  display: block;
}

.button-outline-gray {
  background-color: #f5f5f5;
  border: 1px solid #707070;
  color: #292724;
  font-weight: bold;

  &:hover {
    color: #292724;
    background-color: #fff;
  }
}

.button-large-icon {
  display: flex;
  flex-direction: column;
  line-height: 3rem;

  .icon {
    font-size: 3rem;
  }

  .label {
    font-size: 1.5rem;
  }
}

.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width:767px) {
  .button-row {
    flex-direction: column;
    gap: 10px;

    .button {
      width: 100%;
    }
  }
}