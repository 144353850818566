.uniform {
    .prev-icon,
    .next-icon {
        font-size: 51px;
        color: White;
    }

    .carousel-indicators [data-bs-target] {
        background-color: White !important;
    }
}
