


.hello {
    color: red;
}
.form-headind {
    color: #0095df;
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
}

.fee-card {
    background: #FFFFFF;
    box-shadow: 0px 3px 9px rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 34px;
    margin: 15px 0;
    min-height: 208px;

    .fee {
        display: flex;
        justify-content: space-between;

        h2 {
            font-weight: 700;
            font-size: 32px;
            color: #0095DF;
        }

        h4 {
            font-size: 16px;
            font-weight: 500;
            color: #656565;
        }

        .pic {
            width: 60px;
            height: 60px;
            background-color: #f0f2f5;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            box-shadow: 0px 1px 18px 5px rgb(0 0 0 / 12%);
        }
    }

    p {
        font-size: 20px;
        color: #656565;
        margin: 0;
    }

    .icon {
        display: flex;
        align-content: center;

        i {
            padding-right: 15px;
            font-size: 22px;
        }

        .green {
            color: green;
            font-weight: bold;
        }

        .red {
            color: red;
            font-weight: bold;
        }
    }
}
.fee{
    min-height:0px;
}

@media screen and (max-width: 767px) {
    .fee-card {
        h2 {
            font-size: 1.2rem;
        }

        .line {

            .label {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                img {
                    padding-right: 19px;
                }

                h4 {
                    font-weight: bold;
                    font-size: 1rem;
                    margin: 0;
                }
            }

            .value {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;

                p {
                    margin: 0;
                    font-size: 1rem;
                }

                .price {
                    width: 100%;
                    text-align: center;
                    padding: 10px;
                    margin-top: 14px;

                    h6 {
                        margin: 0;
                        padding: 2px;
                    }
                }
            }
        }
    }
}
