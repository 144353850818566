.vission {
    background-image: url("../images/landing-page/vission.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
} 
.vision {
   text-align: left;
   color:white;
   h5 {
      color: $secondary;
      font-weight:bold;
   }

   h3 {
      font-weight: 600;
      font-size: 27px;
   }

   p {
      font-weight: 300;
      font-size: 15px;
      margin: 20px 0px;
   }

   ul {

      margin: 30px 0px;
      font-size: 18px;
      font-weight: 400;
      list-style: none;

      li {
         i {
            padding-right: 15px;
            

         }
      }
   }
}

.success {
    background-color: black;
    color: white;
    Width: 246px;
    Height: 103px;
    border-radius: 10px;
    position: absolute;
    bottom: 25px;
    left: -64px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    div {
        background: linear-gradient(90deg, #93a8d2 0%, #0095DF 100%);
        border-radius: 10px;
        display: flex;
        Width: 236px;
        Height: 93px;
        text-align: center;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 25px;



        h1 {
            font-weight: 700;
            font-size: 47px;
            margin:0;
        }

        span {
            font-weight: 400;
            font-size: 17px;
        }
    }
}

.rating {

   .student,
   .staff,
   .transport {
      padding: 30px;

      h2 {
         color: black;
         font-weight: 700;
         font-size: 50px;
      }

      h4 {
         font-weight: 500;
         font-size: 24px;
      }
   }

   .student,
   .staff {
      padding-bottom: 30px;
      border-bottom: 1px solid rgb(211, 211, 211);
   }
}

@media screen and (max-width : 767px) {
   .success {
      bottom: 46px;
      left: 54px
   }

   .vision {
      margin-bottom: 70px;
   }
}