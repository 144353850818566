.invoice-page {
    background: #FFF;
    padding: 30px;

    .invoice-top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        position: relative;
        text-align: center;
        background: #EEE;
        border-radius: 10px;
        overflow: hidden;
        padding: 10px;

        img {
            width: 100px;
            margin: auto;
        }

        .title {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;
            width: 100%;
        }

        >span {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            padding-left: 20px;
            text-align: center;
        }


    }

    .invoice-content {
        margin-top: 20px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        overflow: hidden;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                padding: 5px 20px;

                span {
                    width: 50%;
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }

                label {
                    width: 50%;
                    color: $primary;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }


                &:last-child {
                    background: #EEE;
                    font-weight: 600;

                    border-bottom: none;
                    text-align: center;

                    label {
                        color: #000;
                    }
                }
            }
        }
    }
}
