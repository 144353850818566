.sch-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #333333;
    background: white;
    border-radius: 10px;
    padding: 1rem;
    width: 404px;
    height: 218px;
    padding-bottom: 8px;

    &:hover {
        color: #333333;
    }

    >span {
        background-color: #0095DF;
        color: white;
        font-weight: 500;
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        top: -25px;
        min-width: 40px;
        height: 40px;
        font-size: 22px;

        i {
            font-size: 21px;
            transform: rotate(336deg);
        }
    }

    .text {
        overflow: hidden;
        height: 132px;
        margin-top: 2px;

        h1 {
            font-size: 21px;
            font-weight: 600;
            color: #0095df;
            margin-bottom: 5px;
            line-height: 26px;
        }

        p {
            font-size: 16px;

            a {
                color: gray;
            }
        }
    }

    .read-more {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #d6d6d6;
        padding-top: 10px;

        .date {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;

            i {
                font-size: 22px;
                color: $secondary;
            }

            span {
                padding-left: 10px;
            }
        }

        a {
            font-weight: 600;
            color: $secondary;
            transition: all 0.2s ease-in;

            i {
                padding-left: 0px;
                transition: padding-left 0.2s ease-in-out;
            }

            &:hover {
                i {
                    padding-left: 10px;
                    transition: padding-left 0.2s ease-in-out;
                }
            }
        }

    }
}

.news-detail-card {
    background-color: white;
    border-radius: 10px;
    padding: 1rem;

    h1 {
        font-size: 2.2rem;
        font-weight: bold;
        color: $secondary;
    }

    p {
        font-size: 17px;
        color: gray;
    }

    .date {
        display: flex;
        justify-content: start;
        text-align: center;
        align-items: center;

        i {
            font-size: 22px;
            color: $secondary;
        }

        span {
            padding-left: 10px;
        }
    }
}

@media screen and (max-width: 767px) {

    .sch-card {
        margin-bottom: 15px;
        width: 359px;
    }

    
}