@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
body {
  background-color: #F0F2F5;
  font-family: "Inter", sans-serif;
}

ul {
  padding-left: 0;
}

a {
  text-decoration: none;
}

.simple-section {
  padding: 70px;
}
.simple-section .section-title h3 {
  font-weight: 800;
  font-size: 40px;
}
.simple-section .section-title h4 {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 134.02%;
  color: #0095DF;
}

.responsive {
  gap: 40px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1.8rem;
}
.responsive li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.section-detail p {
  font-family: "Roboto Slab", serif;
  padding-top: 10px;
  font-weight: 300;
  font-size: 1rem;
  line-height: 20px;
}

.dps {
  margin-left: 20%;
}

.merit-card {
  padding: 56px;
  background-color: white;
  border-radius: 8px;
}
.merit-card img {
  width: 230px;
}
.merit-card .form-group {
  background: #f0f2f5;
  padding: 38px 28px;
  border-radius: 10px;
}
.merit-card .content {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 767px) {
  .merit-card {
    padding: 35px 15px 15px 15px;
  }
  .merit-card img {
    width: 130px;
  }
  .merit-card .form-group {
    padding: 14px 28px;
  }
  .merit-card .content {
    flex-direction: column;
  }
}
.scrollbar {
  overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar {
  width: 0.6rem;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182);
}

.inline-action {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0px;
}
.inline-action li {
  padding: 0px 4px;
  list-style: none !important;
}
.inline-action li button,
.inline-action li a {
  border-radius: 50% !important;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  font-size: 13px;
}
.inline-action li button span,
.inline-action li button i,
.inline-action li a span,
.inline-action li a i {
  font-size: 13px;
}
.inline-action li .primary {
  border: 1px solid transparent;
  background: #003970;
  color: #ffffff;
}
.inline-action li .primary:hover {
  border: 1px solid #003970;
  background: transparent;
  color: #003970;
}
.inline-action li .red {
  border: 1px solid transparent;
  background: red;
  color: #ffffff;
}
.inline-action li .red:hover {
  border: 1px solid red;
  background: transparent;
  color: red;
}
.inline-action li .black {
  border: 1px solid transparent;
  background: black;
  color: #ffffff;
}
.inline-action li .black:hover {
  border: 1px solid black;
  background: transparent;
  color: black;
}
.inline-action li .gray {
  border: 1px solid transparent;
  background: #333333;
  color: #000000;
}
.inline-action li .gray:hover {
  border: 1px solid #333333;
  background: transparent;
  color: #333333;
}

.choose-photo label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 0.4rem;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  height: 300px;
  cursor: pointer;
}
.choose-photo label i {
  font-size: 5rem;
  color: rgba(0, 0, 0, 0.4);
}
.choose-photo label input {
  position: absolute;
  opacity: 0;
}

.modal-theme.transparent {
  color: #000000;
}
.modal-theme.transparent .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-theme.transparent .modal-header button[aria-label=Close] {
  display: none;
}
.modal-theme.transparent .modal-header .btn-close {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: auto;
  height: auto;
  padding: 0.25em 0.25em;
  color: #000;
  background: none;
  border: 0;
  border-radius: none;
  opacity: 1;
}
.modal-theme.transparent .modal-header .btn-close svg {
  width: 30px;
  color: #0095DF;
}
.modal-theme.transparent .modal-body {
  background: #ffffff;
}
.modal-theme.transparent .modal-footer {
  padding: 1.2rem 2rem;
  background: #ffffff;
}

.student-card {
  display: flex;
  align-items: center;
  gap: 10px;
}
.student-card .card-header {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  min-width: 250px;
}
.student-card .card-header .profile {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #f0f2f5;
  overflow: hidden;
}
.student-card .card-header .profile img {
  width: 100%;
}
.student-card .card-header .user-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
}
.student-card .card-header .user-name h1 {
  color: #003970;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}
.student-card .card-header .user-name p {
  color: #909090;
  font-size: 0.8rem;
  margin: 0;
}
.student-card .menu {
  background: #ffffff;
  padding: 10px 20px;
  color: #000;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  min-height: 215px;
}
.student-card .menu .outline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #d7d7d7;
  width: 100%;
  padding: 4px 0;
}
.student-card .menu .outline b {
  color: #000;
}
.student-card .profile-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 25px 0;
  gap: 10px 0;
}
.student-card .profile-list li:nth-child(odd) {
  color: black;
  font-weight: bold;
  min-width: 20%;
}
.student-card .profile-list li:nth-child(even) {
  color: gray;
  font-weight: 500;
  min-width: 30%;
}

.fee-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fee-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.fee-list li:last-child label {
  font-weight: 600;
}

.captcha-verify-row {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.verify {
  display: flex;
  align-items: center;
  gap: 10px;
}
.verify .Character {
  background: #000000;
  padding: 5px 20px;
  color: #ffffff;
  user-select: none;
  min-width: 110px;
  border-radius: 5px;
  font-size: 21px;
}
.verify .form-group {
  width: 150px;
}

.task {
  height: 18px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.task .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  animation: wave 2s linear infinite;
}

@keyframes wave {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}
@media screen and (max-width: 767px) {
  .school {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .simple-section {
    padding: 70px 8px;
  }
  .simple-section .section-title {
    text-align: center;
    padding-bottom: 1px;
  }
  .simple-section .section-title h3 {
    padding-bottom: 18px;
    font-size: 27px;
  }
  .simple-section .photo {
    margin-top: 35px;
  }
  .section-detail {
    text-align: center;
  }
  .dps {
    margin-left: 0%;
  }
  .captcha-verify-row {
    flex-direction: column;
  }
  .captcha-verify-row .verify {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .captcha-verify-row .verify .Character {
    width: 74%;
    text-align: center;
  }
  .captcha-verify-row .verify a {
    width: 20%;
  }
  .captcha-verify-row .verify .form-group {
    width: 100%;
  }
  .captcha-verify-row button {
    width: 100%;
  }
  .fee-list {
    background: rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    flex-direction: column;
    padding: 12px;
  }
  .student-card {
    flex-direction: column;
  }
  .student-card .card-body, .student-card .card-header {
    width: 100%;
  }
}
.site-header {
  background-color: #fff;
  transition: background 0.5s;
}
.site-header.sticky {
  position: fixed;
  padding: 0;
  z-index: 3;
  background-color: #003970;
}
.site-header.sticky .navbar-brand img {
  max-width: 150px;
}
.site-header .topbar {
  background-color: #003970;
}
.site-header .topbar .top-contact {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}
.site-header .topbar .top-contact li {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0.5rem 0.8rem;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
}
.site-header .topbar .top-contact li:last-child {
  border-right: 0;
}
.site-header .topbar .top-contact li i {
  margin-right: 0.4rem;
  font-size: 1.2rem;
}
.site-header .topbar .top-social {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}
.site-header .topbar .top-social .about-us,
.site-header .topbar .top-social .contact-us {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.site-header .topbar .top-social li {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.7rem;
  font-size: 0.9rem;
}
.site-header .topbar .top-social li a {
  color: #fff;
  font-size: 1rem;
  display: flex;
}
.site-header .topbar .top-social li a span i {
  font-size: 1.4rem;
}
.site-header .topbar .top-social li a:hover {
  color: #0095DF;
}

@media screen and (max-width: 767px) {
  .site-header .topbar {
    background-color: #0095DF;
  }
  .site-header .topbar .top-contact {
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .site-header .topbar .top-social {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .site-header .topbar .top-social li {
    align-items: center;
    padding: 8px;
  }
  .site-header .topbar .top-social li a {
    font-size: 0.9rem;
  }
}
header {
  width: 100%;
  z-index: 99;
}
header .logo {
  width: 270px;
  margin-left: 18px;
}
header .logo-1 {
  display: none;
}
header .navbar-nav .nav-item .nav-link {
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  transition: 0.3s all;
  position: relative;
}
header .navbar-nav .nav-item .nav-link:hover {
  color: #000000;
}
header form .nav-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
header form span i {
  padding-right: 5px;
  font-size: 1.2rem;
}

nav.navbar.navbar-expand-lg.bg-white {
  padding: 0;
}

.sticky {
  position: fixed;
  top: 0;
  background-color: white;
  box-shadow: 10px -27px 26px 8px black;
}

button.navbar-toggler {
  display: none;
}

header.scrolled {
  position: sticky;
  top: 0;
  background: white;
  box-shadow: 0px 0px 16px rgba(0, 124, 195, 0.25);
}

@media screen and (max-width: 1332px) {
  header .logo {
    display: none;
  }
  header .logo-1 {
    width: 60px;
    display: block;
  }
  header .navbar-nav .nav-item .nav-link {
    padding: 0.5rem 0.6rem;
    font-size: 13.5px;
  }
  header form .nav-link {
    font-size: 12px;
    padding: 7px 6px;
  }
}
@media screen and (max-width: 400px) {
  header .logo {
    width: 250px;
    margin-left: 0;
  }
}
footer {
  background-image: url("../images/images/footer-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 110px;
}
footer .help {
  background-image: url("../images/images/ctabg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-top: -120px;
}
footer .help .footer-contact {
  padding: 107px;
}
footer .help .footer-contact h3 {
  color: #003970;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}
footer .help .footer-contact p {
  color: #c3c3c3;
  margin-bottom: 10px;
}
footer .help .footer-contact h5 {
  color: #303030;
  margin: 0;
}
footer .border-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 50px;
}
footer .border-line .Quick {
  line-height: 25px;
}
footer .border-line .Quick h4 {
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 50px 0px 30px 0px;
}
footer .border-line .Quick ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
footer .border-line .Quick ul li a {
  color: white;
}
footer .border-line .Quick ul li a {
  text-decoration: none;
  color: white;
  line-height: 34px;
  font-size: 16px;
}
footer .border-line .Quick ul li a i {
  width: 30px;
  height: 50px;
  font-size: 20px;
  font: bold;
  padding: 10px;
  background-color: gainsboro;
}

.Right {
  background-color: rgba(41, 51, 51, 0.7);
}

@media screen and (max-width: 767px) {
  footer {
    margin-top: 0;
  }
  footer .help {
    background-image: none;
    padding: 20px;
    background-color: white;
    margin-top: 94px;
    border-radius: 8px;
  }
  footer .help .footer-contact {
    padding: 10px 0 20px 0px;
  }
  footer .help .footer-contact h3 {
    margin-bottom: 10px;
  }
  footer .help .footer-contact p {
    margin-bottom: 10px;
  }
  footer .help .footer-contact h5 {
    font-size: 1.1rem;
  }
  footer .Quick h4 {
    padding: 46px 0px 12px 0px;
  }
  footer .Quick .logo-dark {
    padding-top: 1%;
    padding-right: 0px;
  }
}
.child {
  padding: 92px 60px;
}
.child h2 {
  color: white;
  font-weight: 800;
  font-size: 38px;
  line-height: 45px;
}

.friend {
  background: linear-gradient(90deg, #003399 0%, #0095DF 100%);
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  .child {
    padding: 45px 52px;
  }
  .child h2 {
    padding-bottom: 18px;
  }
}
@media screen and (max-width: 767px) {
  .syllabus-img {
    padding-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .job-img {
    padding-top: 50px;
  }
}
.school {
  align-items: flex-end;
}

.photo-card {
  border-radius: 7px;
  background-color: #F2F2F2;
  width: 25.25rem;
}
.photo-card img {
  width: 25.25rem;
  height: 230px;
}
.photo-card .card-body {
  padding: 30px;
  min-height: 126px;
}
.photo-card .card-body .card-text {
  font-family: "Roboto Slab", serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: black;
}

.photo-detail-card {
  border-radius: 7px;
  background-color: white;
  width: 100%;
}
.photo-detail-card img {
  width: 100%;
  height: 230px;
}
.photo-detail-card .card-body {
  padding: 30px;
  min-height: 126px;
}
.photo-detail-card .card-body .card-text {
  font-family: "Roboto Slab", serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: black;
}

@media screen and (max-width: 767px) {
  .photo-card {
    margin-bottom: 17px;
    width: 22.25rem;
  }
  .photo-card img {
    width: 100%;
  }
  .photo-card .card-body {
    padding: 30px;
    min-height: 126px;
  }
  .photo-card .card-body .card-text {
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
  }
  .school {
    align-items: flex-end;
  }
}
.sch-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333333;
  background: white;
  border-radius: 10px;
  padding: 1rem;
  width: 404px;
  height: 218px;
  padding-bottom: 8px;
}
.sch-card:hover {
  color: #333333;
}
.sch-card > span {
  background-color: #0095DF;
  color: white;
  font-weight: 500;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  top: -25px;
  min-width: 40px;
  height: 40px;
  font-size: 22px;
}
.sch-card > span i {
  font-size: 21px;
  transform: rotate(336deg);
}
.sch-card .text {
  overflow: hidden;
  height: 132px;
  margin-top: 2px;
}
.sch-card .text h1 {
  font-size: 21px;
  font-weight: 600;
  color: #0095df;
  margin-bottom: 5px;
  line-height: 26px;
}
.sch-card .text p {
  font-size: 16px;
}
.sch-card .text p a {
  color: gray;
}
.sch-card .read-more {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d6d6d6;
  padding-top: 10px;
}
.sch-card .read-more .date {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.sch-card .read-more .date i {
  font-size: 22px;
  color: #0095DF;
}
.sch-card .read-more .date span {
  padding-left: 10px;
}
.sch-card .read-more a {
  font-weight: 600;
  color: #0095DF;
  transition: all 0.2s ease-in;
}
.sch-card .read-more a i {
  padding-left: 0px;
  transition: padding-left 0.2s ease-in-out;
}
.sch-card .read-more a:hover i {
  padding-left: 10px;
  transition: padding-left 0.2s ease-in-out;
}

.news-detail-card {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
}
.news-detail-card h1 {
  font-size: 2.2rem;
  font-weight: bold;
  color: #0095DF;
}
.news-detail-card p {
  font-size: 17px;
  color: gray;
}
.news-detail-card .date {
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
}
.news-detail-card .date i {
  font-size: 22px;
  color: #0095DF;
}
.news-detail-card .date span {
  padding-left: 10px;
}

@media screen and (max-width: 767px) {
  .sch-card {
    margin-bottom: 15px;
    width: 359px;
  }
}
.events-card {
  border-radius: 0.625rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #FFF !important;
  width: 25.25rem;
  min-height: 25.25rem;
  color: black;
  overflow: hidden;
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
}
.events-card img {
  animation-name: zoom;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.events-card img {
  width: 25.25rem;
  height: 215px;
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.events-card .detail {
  background-color: white;
  padding: 1rem;
  width: 25.25rem;
  height: 11.5625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.events-card .detail .card-detail .date {
  padding-bottom: 2px;
  align-items: center;
  display: flex;
}
.events-card .detail .card-detail .date span {
  color: black;
  font-weight: 500;
}
.events-card .detail .card-detail .date i {
  margin-right: 10px;
  font-size: 17px;
  color: #0095DF;
}
.events-card .detail .card-detail h5 {
  color: black;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 140.523%;
}
.events-card .detail .card-detail p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 137.023%;
}
.events-card .detail a {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  color: #0095DF;
  padding-top: 0.9rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.events-card .detail a .ri-arrow-right-line {
  font-size: 20px;
}
.events-card .detail a:hover {
  animation-name: fade-in;
  animation-duration: 0.5s;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.events-img {
  width: 100%;
  height: 215px;
}

.events-detail-card {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}
.events-detail-card .event-body h1 {
  font-size: 2.2rem;
  font-weight: bold;
}
.events-detail-card .event-body p {
  font-size: 14px;
  line-height: 19px;
}
.events-detail-card .date {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
  align-items: center;
  display: flex;
}
.events-detail-card .date span {
  color: black;
  font-weight: 500;
}
.events-detail-card .date i {
  font-size: 17px;
  margin-right: 10px;
  color: #0095DF;
}

@media screen and (max-width: 767px) {
  .events-img {
    width: 25.25rem;
    height: 200px;
  }
  .events-card {
    width: 22.25rem;
    min-height: 22.25rem;
    margin-bottom: 15px;
  }
}
.massage-container {
  background-color: #003970;
}
.massage-container .row .offset-md-4 .massage-detail {
  padding: 100px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  margin: 90px 0;
  z-index: 2;
}
.massage-container .row .offset-md-4 .massage-detail .profile {
  display: none;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.massage-container .row .offset-md-4 .massage-detail h1 {
  font-weight: bolder;
  font-size: 22px;
  line-height: 146.02%;
}
.massage-container .row .offset-md-4 .massage-detail p {
  font-weight: 400;
  font-size: 14px;
  line-height: 146.02%;
  color: rgba(41, 51, 51, 0.7);
}
.massage-container .row .offset-md-4 .massage-detail h3 {
  font-weight: bold;
  font-size: 17px;
  line-height: 146.02%;
  color: #003970;
}
.massage-container .row .offset-md-4 .massage-detail span {
  font-weight: 400;
  font-size: 13px;
  line-height: 135.52%;
  color: rgba(41, 51, 51, 0.7);
}
.massage-container .row .offset-md-4 > .profile {
  position: absolute;
  top: 175px;
  left: 175px;
  z-index: 2;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 325px;
  height: 325px;
}
.massage-container .row .offset-md-4 > .profile img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.massage-container .row .offset-md-4 > .profile::after {
  content: "";
  position: absolute;
  top: -30px;
  left: -30px;
  border-radius: 10px;
  border: 1px solid #0095DF;
  width: 325px;
  height: 325px;
  overflow: hidden;
  z-index: -1;
}
.massage-container .row .offset-md-4 .dots {
  z-index: 1;
  position: absolute;
  top: 23px;
  right: 157px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 3rem;
  height: 3rem;
  margin-right: 38px;
}

@media screen and (max-width: 1399px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none;
  }
  .massage-container .row .offset-md-4 {
    width: 100% !important;
    margin-left: 0%;
  }
  .massage-container .row .offset-md-4 .massage-detail {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 41px;
    padding-top: 20px;
  }
  .massage-container .row .offset-md-4 .massage-detail .profile {
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }
  .massage-container .row .offset-md-4 .massage-detail .profile img {
    width: 15rem;
  }
  .massage-container .row .offset-md-4 > .profile {
    display: none;
  }
  .massage-container .row .offset-md-4 .dots {
    top: -56px;
    right: -7px;
  }
}
.result .results-img {
  overflow: hidden;
  border-radius: 31px;
  border: 4px solid #ff4689;
}
.result .results-img img {
  border-radius: 16px;
  transform: scale(1.1);
}
.result .section-detail {
  background-color: #ff4689;
  color: white;
  border-radius: 10px;
  padding: 18px;
}

@media screen and (max-width: 767px) {
  .results-img {
    padding-bottom: 50px;
  }
}
.hero-video {
  width: 100%;
  height: auto;
}

.hero-text {
  padding: 20px;
  text-align: center;
  width: 34%;
  /*background-color: #ffeff2;*/
  background-color: white;
  margin: auto;
  position: relative;
  top: -137px;
  right: 0;
  border-radius: 15px;
}
.hero-text h4 {
  font-weight: 500;
  font-size: 20px;
  color: #0095DF;
  font-weight: bold;
}
.hero-text h1 {
  font-weight: 800;
  font-size: 27px;
  text-transform: uppercase;
}
.hero-text p {
  font-weight: 300;
  font-size: 15px;
}
.hero-text h2 {
  font-size: 24px;
}
.hero-text h2 span {
  color: #0095DF;
}

@media screen and (max-width: 767px) {
  .hero-text {
    width: 93%;
    padding: 10px;
    top: -23px;
  }
  .hero-text h4 {
    font-weight: bold;
    font-size: 21px;
  }
  .hero-text h1 {
    font-weight: bold;
    font-size: 20px;
  }
  .hero-text p {
    font-size: 15px;
  }
}
.vission {
  background-image: url("../images/landing-page/vission.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.vision {
  text-align: left;
  color: white;
}
.vision h5 {
  color: #0095DF;
  font-weight: bold;
}
.vision h3 {
  font-weight: 600;
  font-size: 27px;
}
.vision p {
  font-weight: 300;
  font-size: 15px;
  margin: 20px 0px;
}
.vision ul {
  margin: 30px 0px;
  font-size: 18px;
  font-weight: 400;
  list-style: none;
}
.vision ul li i {
  padding-right: 15px;
}

.success {
  background-color: black;
  color: white;
  Width: 246px;
  Height: 103px;
  border-radius: 10px;
  position: absolute;
  bottom: 25px;
  left: -64px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.success div {
  background: linear-gradient(90deg, #93a8d2 0%, #0095DF 100%);
  border-radius: 10px;
  display: flex;
  Width: 236px;
  Height: 93px;
  text-align: center;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 25px;
}
.success div h1 {
  font-weight: 700;
  font-size: 47px;
  margin: 0;
}
.success div span {
  font-weight: 400;
  font-size: 17px;
}

.rating .student,
.rating .staff,
.rating .transport {
  padding: 30px;
}
.rating .student h2,
.rating .staff h2,
.rating .transport h2 {
  color: black;
  font-weight: 700;
  font-size: 50px;
}
.rating .student h4,
.rating .staff h4,
.rating .transport h4 {
  font-weight: 500;
  font-size: 24px;
}
.rating .student,
.rating .staff {
  padding-bottom: 30px;
  border-bottom: 1px solid rgb(211, 211, 211);
}

@media screen and (max-width: 767px) {
  .success {
    bottom: 46px;
    left: 54px;
  }
  .vision {
    margin-bottom: 70px;
  }
}
.contact {
  border: 1px solid #0095DF;
  align-items: center;
  text-align: center;
  padding: 50px 30px 47px 30px;
  border-radius: 5px;
  margin: 0;
  min-height: 310px;
}
.contact i {
  font-size: 50px;
  color: #0095DF;
}
.contact h5 {
  font-size: 22px;
  margin-bottom: 25px;
}
.contact .number p {
  font-size: 15px;
  color: #333333;
  line-height: 16px;
}

.map iframe {
  border-radius: 0.5rem;
}

@media screen and (max-width: 767px) {
  .contact {
    margin-bottom: 17px;
  }
}
.button {
  display: inline-block;
  vertical-align: middle;
  border: none;
  text-align: center;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 10px;
  border-radius: 8px;
}
.button.hover-on-dark:hover {
  color: #fff;
  border-color: #fff;
}
.button:disabled {
  opacity: 0.7;
}

.button-long {
  min-width: 130px;
}

.button-dark {
  background-color: #000000;
  color: #fff;
}
.button-dark:hover {
  background-color: transparent;
  border-color: #000000;
  color: #000000;
}

.button-icon {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  padding: 0.6rem 0.6rem 0.6rem 1.3rem;
  white-space: nowrap;
  align-items: center;
  border-radius: 8px;
  gap: 10px;
}
.button-icon span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}
.button-icon.icon-right span {
  margin-left: 0.8rem;
}
.button-icon.icon-left {
  flex-direction: row-reverse;
  justify-content: center;
  padding: 0.6rem 1rem 0.6rem 1rem;
}
.button-icon.icon-left span,
.button-icon.icon-left i {
  margin-left: 0;
  margin-right: 0.4rem;
}
.button-icon.button-dark span {
  background-color: #003970;
  color: #000000;
}

.button-white {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.0431372549);
  color: #003970;
}
.button-white:hover {
  background-color: #f1f1f1;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.button-primary {
  background-color: #003970;
  color: #fff;
}
.button-primary:hover {
  background-color: transparent;
  border-color: #003970;
  color: #003970;
  opacity: 1;
}

.button-secondary {
  background-color: #0095DF;
  color: #fff;
  font-weight: 600;
  border: 1px solid transparent;
  border-color: transparent;
}
.button-secondary span {
  font-size: 1.4rem;
}
.button-secondary:hover, .button-secondary:focus {
  background-color: transparent;
  border-color: #0095DF;
  color: #0095DF;
  opacity: 1;
}

.button-outline-secondary {
  border-color: #0095DF;
  color: #0095DF;
  background-color: transparent;
}
.button-outline-secondary:hover {
  color: #fff;
  background-color: #0095DF;
  border: 1px solid #0095DF;
}

.button-outline-primary {
  border-color: #003970;
  color: #003970;
  background-color: transparent;
}
.button-outline-primary:hover {
  color: #fff;
  background-color: #003970;
}

.button-fb {
  background-color: #3c5a9a;
  color: #fff;
  font-weight: bold;
}
.button-fb:hover {
  background-color: transparent;
  border-color: #3c5a9a;
  color: #3c5a9a;
  opacity: 1;
}

.button-success {
  background-color: rgb(97, 229, 156);
  color: #fff;
}
.button-success:hover {
  color: #fff;
  opacity: 0.8;
}

.button-danger {
  background-color: #E21213;
  color: #fff;
}
.button-danger:hover {
  color: #fff;
  opacity: 0.8;
}

.button-round {
  border-radius: 0.4rem;
}

.button-block {
  width: 100%;
  display: block;
}

.button-outline-gray {
  background-color: #f5f5f5;
  border: 1px solid #707070;
  color: #292724;
  font-weight: bold;
}
.button-outline-gray:hover {
  color: #292724;
  background-color: #fff;
}

.button-large-icon {
  display: flex;
  flex-direction: column;
  line-height: 3rem;
}
.button-large-icon .icon {
  font-size: 3rem;
}
.button-large-icon .label {
  font-size: 1.5rem;
}

.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 767px) {
  .button-row {
    flex-direction: column;
    gap: 10px;
  }
  .button-row .button {
    width: 100%;
  }
}
.page-head {
  background-image: url("../images/banner.jpg");
  min-height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.page-head h3 {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
}
.page-head .breadcrumb li {
  color: rgba(255, 255, 255, 0.6);
}
.page-head .breadcrumb li a {
  color: rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 767px) {
  .page-head h3 {
    font-size: 1.5rem;
  }
}
.pagination {
  --bs-pagination-bg: #ffffff;
  --bs-pagination-color: $secondary;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: transparant;
  --bs-pagination-hover-color: $secondary;
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: transparant;
  --bs-pagination-focus-color: white;
  --bs-pagination-focus-bg: #007efd;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #000000;
  --bs-pagination-active-bg: rgb(255, 255, 255);
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #fff;
  --bs-pagination-disabled-bg: #0095DF;
  --bs-pagination-disabled-border-color: #007fff;
}
.pagination .next {
  padding: 6px 24px;
}

.page-item .page-link {
  border-radius: 50%;
  margin: 3px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.page-item .page-link:focus {
  background: #0095DF;
  color: white;
}
.page-item:hover {
  color: #0095DF;
}
.page-item:first-child .page-link {
  border-radius: 50%;
  background: #0095DF;
  color: white;
  margin-left: 0px;
  border-top-left-radius: 0;
}
.page-item:last-child .page-link {
  border-radius: 50%;
  background: #0095DF;
  color: white;
  margin-left: 0px;
  border-bottom-right-radius: 0;
}

.table.table-theme thead {
  background-color: rgba(0, 149, 223, 0.5);
}
.table.table-theme tbody tr td {
  font-size: 1rem;
  vertical-align: middle;
}
.table.table-theme tbody tr:last-child {
  border-bottom: transparent;
}
.table thead tr th {
  font-size: 1rem;
}
.table-actions a {
  font-size: 1.3rem;
}

.table > :not(:first-child) {
  border-top: 0;
}

.va-middle td,
.va-middle th {
  vertical-align: middle;
}

.table-add-sale .col-input {
  max-width: 70px;
}

.date-sheetpage table {
  background-color: #fff !important;
  padding: 10px !important;
}
.date-sheetpage table thead {
  width: 100% !important;
  background-color: rgba(0, 149, 223, 0.5);
}
.date-sheetpage table thead th {
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  border-bottom-width: 1px;
  border-color: #dbdedf;
}
.date-sheetpage table tbody {
  width: 100% !important;
}
.date-sheetpage table tbody tr {
  padding: 0.5rem 0.5rem;
  border-bottom-width: 1px;
  border-color: #dbdedf;
}
.date-sheetpage table tbody tr:nth-of-type(odd) {
  background: #eef0f1;
}
.date-sheetpage table tbody tr td {
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}
.date-sheetpage table tbody tr:hover {
  background: #eef0f1;
}

@media screen and (max-width: 767px) {
  .card {
    overflow: scroll;
  }
  .schedule-mobile {
    overflow: scroll;
    width: 45rem;
  }
  .table {
    /* transform: rotateZ(90deg);
    margin: 35px 0px;
    width: 100%;*/
  }
  .table.table-theme thead {
    background-color: rgba(0, 149, 223, 0.5);
  }
  .table.table-theme tbody tr td {
    font-size: 0.8rem;
  }
  .table.table-theme tbody > :not(:first-child) {
    font-size: 3rem;
  }
  .table thead tr th {
    font-size: 0.8rem;
  }
}
.offcanvas.offcanvas-end.show .accordion {
  border: transparent;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0.8rem;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item {
  border: transparent;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header {
  padding: 0.3rem 0;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: #cfe3fe;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed),
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header button {
  font-size: 0.9rem;
  border-radius: 0.8rem;
  color: rgb(0, 0, 0);
  font-weight: 500;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):hover, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):focus, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header button:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header button:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header button:active {
  background-color: rgba(82, 189, 251, 0.4509803922);
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body {
  padding: 4px 10px;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu {
  list-style: none;
  margin: 0;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  border-radius: 0.8rem;
  color: black;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a:hover, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a:focus, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a:active, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a:target,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a:active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a:target,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a:active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a:target,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a:active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a:target {
  background-color: rgba(82, 189, 251, 0.4509803922);
}

.btn-close {
  z-index: 50;
}

.toggle-side-menu {
  color: black;
  display: none;
}

/* Styles for screens with a maximum width of 1332px */
@media screen and (max-width: 992px) {
  header .logo {
    display: block;
  }
  header .logo-1 {
    width: 60px;
    display: none;
  }
  .site-header {
    display: none;
  }
  .navbar-expand-lg .navbar-collapse {
    display: none !important;
  }
  .toggle-side-menu {
    display: block;
    z-index: 10;
  }
  .toggle-side-menu a {
    color: black;
  }
  .toggle-side-menu a i {
    font-size: 30px !important;
  }
}
.scrollbar {
  overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #0095DF;
  border-radius: 20px;
}
.scrollbar::-moz-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-moz-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-moz-scrollbar-thumb {
  background-color: #0095DF;
  border-radius: 20px;
}
.scrollbar::-ms-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-ms-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-ms-scrollbar-thumb {
  background-color: #0095DF;
  border-radius: 20px;
}
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #0095DF rgba(0, 0, 0, 0.1);
}

.input-icon {
  position: relative;
}
.input-icon .form-control {
  min-height: 52px;
  border-radius: 50px;
  padding-left: 4rem;
  color: #0095DF;
}
.input-icon .form-control:focus {
  outline: 1px solid #0095DF;
  box-shadow: none;
}
.input-icon span {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(215, 36, 29, 0.2);
  position: absolute;
  left: 10px;
  top: 8px;
  border-radius: 50%;
  color: gray;
  font-size: 1.6rem;
}

.form-control:focus,
.form-select:focus {
  outline: 1px solid #0095DF;
  box-shadow: none;
}
.form-control:focus ~ label,
.form-select:focus ~ label {
  color: #0095DF;
}

.form-group {
  padding: 0px;
}
.form-group label {
  margin-bottom: 0.3rem;
  font-weight: 500;
  font-size: 0.9rem;
}
.form-group .form-control,
.form-group .form-select {
  border-radius: 5px;
  min-height: 40px;
}

.form-section-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.add-student-form .form-group {
  margin-bottom: 2rem;
}

.card-form-wrap {
  padding: 2rem 2rem;
}

.form-wrap {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem;
}
.form-wrap .row {
  margin: 1rem 0rem 1rem 0rem;
  align-items: center;
}
.form-wrap .row .label-value {
  background-color: rgba(0, 0, 0, 0.02);
  min-height: 2.3rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
}
.form-wrap .form-control,
.form-wrap .form-select {
  background-color: rgba(0, 0, 0, 0.02);
}
.form-wrap .col-form-label {
  text-align: right;
}
.form-wrap .form-legend {
  position: absolute;
  display: inline-block;
  background-color: #FFF;
  padding: 0.4rem 1rem;
  top: -22px;
  left: 25.5%;
  font-weight: bold;
  font-size: 1.2rem;
  color: #0095DF;
}
.form-wrap.label-left .form-legend {
  left: 1.5%;
}

.form-style-1 .form-group label {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
}
.form-style-1 .form-control,
.form-style-1 .form-select {
  background-color: #F2F7FA;
  border-color: rgba(0, 0, 0, 0.1);
  min-height: 40px;
}
.form-style-1 input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.3);
}
.form-style-1 input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.3);
}
.form-style-1 input:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.3);
}
.form-style-1 input:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.3);
}

.label-gray {
  background-color: rgba(0, 0, 0, 0.02);
  min-height: 2.7rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
}

.invoice-page {
  background: #FFF;
  padding: 30px;
}
.invoice-page .invoice-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #EEE;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
}
.invoice-page .invoice-top img {
  width: 100px;
  margin: auto;
}
.invoice-page .invoice-top .title {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 100%;
}
.invoice-page .invoice-top > span {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-left: 20px;
  text-align: center;
}
.invoice-page .invoice-content {
  margin-top: 20px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.invoice-page .invoice-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.invoice-page .invoice-content ul li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding: 5px 20px;
}
.invoice-page .invoice-content ul li span {
  width: 50%;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.invoice-page .invoice-content ul li label {
  width: 50%;
  color: #003970;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.invoice-page .invoice-content ul li:last-child {
  background: #EEE;
  font-weight: 600;
  border-bottom: none;
  text-align: center;
}
.invoice-page .invoice-content ul li:last-child label {
  color: #000;
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 0px;
  min-width: 300px;
}
.user img {
  width: 230px;
  height: 230px;
  border-radius: 50%;
}
.user p {
  margin: 0;
  font-weight: 600;
  font-size: 0.9rem;
}
.user h6 {
  margin: 0;
  color: #003970;
  font-weight: bold;
  font-size: 1.2rem;
}
.user span {
  color: rgba(41, 51, 51, 0.7);
}

@media screen and (max-width: 767px) {
  .user {
    margin: 5px 0px;
  }
}
.syllabus {
  gap: 40px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.syllabus li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.syllabus li .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 4px solid #003970;
  border-radius: 50%;
  height: 265px;
  width: 265px;
  position: relative;
}
.syllabus li .circle img {
  position: absolute;
  bottom: -12px;
  right: -9px;
}
.syllabus li .circle .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  height: 228.5px;
  width: 228.5px;
  border-radius: 50%;
  z-index: 2;
}
.syllabus li .circle .content h4 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #003970;
}
.syllabus li .circle .content h3 {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  color: #003970;
}
.syllabus li .circle .content .class {
  color: rgba(41, 51, 51, 0.7);
  font-size: 24px;
}
.syllabus li .circle .content a {
  border-radius: 28px;
  margin-top: 10px;
}
.syllabus li .circle .content a span {
  font-size: 1rem;
}

.faculty-card {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 17px;
  padding: 23px;
  width: 416px;
}
.faculty-card h2 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
  position: relative;
}
.faculty-card h2::after {
  content: "";
  display: block;
  width: 20%;
  height: 2px;
  background-color: black;
  position: absolute;
  top: 26px;
}
.faculty-card .content {
  text-align: left;
  margin: 25px 0px 20px 0px;
}
.faculty-card .content p {
  margin: 0;
}
.faculty-card .experience {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: baseline;
}
.faculty-card .experience span {
  width: 85px;
  height: 35px;
  background-color: #0095DF;
  border-radius: 6px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.faculty-card .experience p {
  margin-left: 10px;
  color: #7f8083;
}

.thk-you {
  font-size: 1.3rem;
}
.thk-you h3 {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .faculty-card h2::after {
    top: 30px;
  }
}
.bog-member {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 55px 20px 27px 20px;
  position: relative;
  min-height: 208px;
  margin: 23px 0px;
  width: 305px;
}
.bog-member .img-wrap {
  position: absolute;
  top: -85px;
  left: 20px;
  width: 130px;
  height: 130px;
  border: 4px solid #0095DF;
  border-radius: 50%;
  overflow: hidden;
}
.bog-member img {
  width: 100%;
  object-fit: contain;
}
.bog-member p {
  margin: 0;
  font-weight: 600;
  font-size: 0.9rem;
}
.bog-member h3 {
  margin: 1px 0 0.5rem 0;
  color: #003970;
  font-weight: 500;
  font-size: 1.2rem;
  max-width: 197px;
}
.bog-member span {
  color: rgba(41, 51, 51, 0.7);
}

.bog-responsive {
  gap: 50px 25px !important;
}

@media screen and (max-width: 767px) {
  .bog-member {
    margin: 57px 0px;
  }
  .bog-responsive {
    gap: 0px 40px !important;
  }
}
.registration-card {
  background: #ffffff;
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 56px;
}
.registration-card h3 {
  font-weight: 600;
  font-size: 26px;
  color: #003970;
  margin: 0;
}
.registration-card p {
  font-size: 15px;
  color: black;
  margin: 0;
}
.registration-card .result-card {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 43px;
  position: relative;
}
.registration-card .result-card h5 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}
.registration-card .result-card input {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  min-height: 45px;
}
.registration-card .result-card input:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  .registration-card {
    padding: 20px;
  }
  .registration-card .result-card {
    padding: 22px 10px;
  }
}
.hello {
  color: red;
}

.form-headind {
  color: #0095df;
  font-weight: bold;
  text-align: center;
  font-size: 2rem;
}

.fee-card {
  background: #FFFFFF;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 34px;
  margin: 15px 0;
  min-height: 208px;
}
.fee-card .fee {
  display: flex;
  justify-content: space-between;
}
.fee-card .fee h2 {
  font-weight: 700;
  font-size: 32px;
  color: #0095DF;
}
.fee-card .fee h4 {
  font-size: 16px;
  font-weight: 500;
  color: #656565;
}
.fee-card .fee .pic {
  width: 60px;
  height: 60px;
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 1px 18px 5px rgba(0, 0, 0, 0.12);
}
.fee-card p {
  font-size: 20px;
  color: #656565;
  margin: 0;
}
.fee-card .icon {
  display: flex;
  align-content: center;
}
.fee-card .icon i {
  padding-right: 15px;
  font-size: 22px;
}
.fee-card .icon .green {
  color: green;
  font-weight: bold;
}
.fee-card .icon .red {
  color: red;
  font-weight: bold;
}

.fee {
  min-height: 0px;
}

@media screen and (max-width: 767px) {
  .fee-card h2 {
    font-size: 1.2rem;
  }
  .fee-card .line .label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .fee-card .line .label img {
    padding-right: 19px;
  }
  .fee-card .line .label h4 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }
  .fee-card .line .value {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .fee-card .line .value p {
    margin: 0;
    font-size: 1rem;
  }
  .fee-card .line .value .price {
    width: 100%;
    text-align: center;
    padding: 10px;
    margin-top: 14px;
  }
  .fee-card .line .value .price h6 {
    margin: 0;
    padding: 2px;
  }
}
.promotion-policy {
  padding-left: 0px;
}

@media screen and (max-width: 767px) {
  .promotion-policy {
    padding-left: 5px;
  }
}
.home-work {
  background: #FFFFFF;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 15px 0;
  text-align: center;
}
.home-work h2 {
  font-weight: 700;
  font-size: 30px;
  color: #0095DF;
}
.home-work h5 {
  font-weight: 500;
  font-size: 20px;
}
.home-work a {
  justify-content: center;
}
.home-work a i {
  margin-left: 10px;
}

.uniform .prev-icon,
.uniform .next-icon {
  font-size: 51px;
  color: White;
}
.uniform .carousel-indicators [data-bs-target] {
  background-color: White !important;
}

.age-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
}
.age-card p {
  font-weight: 600;
  font-size: 16px;
  color: #656565;
}
.age-card div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.age-card div .img-fluid {
  max-width: 40%;
}
.age-card div h2 {
  font-weight: 700;
  font-size: 24px;
  color: #0095DF;
  margin: 0px;
}
.age-card div h3 {
  font-weight: 600;
  font-size: 20px;
  padding-top: 5px;
  margin: 0px;
}

.prospectus {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
}
.prospectus p {
  font-weight: 600;
  font-size: 16px;
  color: #656565;
}
.prospectus div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.prospectus div .img-fluid {
  max-width: 50%;
}
.prospectus div button {
  justify-content: center;
}

.wing-card {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.wing-card:hover .wing-text {
  height: 221px;
  transition: all 0.5s ease-in-out;
}
.wing-card:hover img {
  transition: all 0.5s ease-in-out;
  transform: scale(1.1);
}
.wing-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.wing-card .wing-text {
  flex: 1 1 auto;
  padding: 1rem;
  text-align: center;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  height: 81px;
}
.wing-card .wing-text span {
  color: black;
  font-family: "Roboto Slab";
}
.wing-card .wing-text h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.wing-card .wing-text p {
  margin: 0;
  overflow: hidden;
}

.tree {
  width: 100%;
  background-color: #1B2034;
  background-image: url("../images/Introduction/tree.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.white-box {
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  width: 25%;
  text-align: center;
  margin: auto;
  margin-top: 25px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.white-box p {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  color: #0095DF;
}
.white-box span {
  font-weight: 400;
  font-size: 17px;
  color: #333333;
}

.semester {
  padding: 0.4rem 0;
}
.semester p {
  font-weight: 700;
  font-size: 30px;
  margin: 0;
}
.semester span {
  font-weight: 400;
  font-size: 17px;
  color: #0095DF;
}

.schedule-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.schedule-card h5 {
  font-weight: 700;
  font-size: 19px;
  margin: 14px 0px 0px 0px;
}
.schedule-card span {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
}

.login-wrap {
  position: relative;
}
.login-wrap > img {
  position: absolute;
  left: 0;
  top: -100px;
}
.login-wrap .login-box {
  background: #FFFFFF;
  border: 1px solid rgba(54, 61, 77, 0.1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  position: relative;
  padding: 4rem;
}
.login-wrap .login-box .logo {
  margin-bottom: 2rem;
}
.login-wrap .login-box .logo img {
  max-width: 300px;
}
.login-wrap .login-box h2 {
  font-size: 1.4rem;
  font-weight: bold;
}
.login-wrap .login-box .form-group {
  position: relative;
}
.login-wrap .login-box .form-group .form-control {
  min-height: 54px;
  padding-left: 56px;
}
.login-wrap .login-box .form-group .form-control:focus {
  border-color: #0095DF;
  box-shadow: none;
}
.login-wrap .login-box .form-group .form-control:focus ~ i span {
  color: #0095DF;
}
.login-wrap .login-box .form-group i {
  position: absolute;
  top: 10px;
  left: 14px;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.4);
}
.login-wrap .login-box .form-group span {
  position: absolute;
  top: 10px;
  right: 14px;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.login-wrap .login-box .form-group span:active {
  color: #0095DF;
}
.login-wrap .login-box .form-group .ri-eye-off-line {
  color: #0095DF;
}

@media screen and (max-width: 992px) {
  .login-wrap {
    position: relative;
  }
  .login-wrap > img {
    left: 24px;
    top: -47px;
    width: 335px;
  }
  .login-wrap .login-box {
    padding: 1.5rem;
  }
  .login-wrap .login-box .logo {
    margin-bottom: 0.6rem;
  }
  .login-wrap .login-box .logo img {
    max-width: 215px;
  }
  .login-wrap .login-box h2 {
    font-size: 1.2rem;
    margin-bottom: 10px !important;
  }
  .login-wrap .login-box .form-group .form-control {
    min-height: 38px;
    padding-left: 45px;
  }
  .login-wrap .login-box .form-group i {
    top: 5px;
    left: 14px;
    font-size: 1.2rem;
  }
  .login-wrap .login-box .form-group span {
    top: 7px;
    right: 14px;
    font-size: 1.1rem;
  }
}
.dashboard {
  margin-top: 3rem;
}

.dashboard-buttons {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dashboard-buttons li {
  flex-basis: 18%;
  margin: 0.5rem;
}
.dashboard-buttons li a {
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  border: 1px solid transparent;
  color: #003970;
}
.dashboard-buttons li a:hover {
  border-color: #0095DF;
  color: #0095DF;
}
.dashboard-buttons li a img {
  margin-bottom: 1rem;
}
.dashboard-buttons li a span {
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .dashboard-buttons li {
    flex-basis: auto;
    width: 150.675px;
  }
}
.add_class_options {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.add_class_options li {
  width: 50%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  justify-content: space-between;
}
.add_class_options li:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.add_class_options li .sr {
  width: 10%;
  font-size: 0.9rem;
  display: none;
  color: rgba(0, 0, 0, 0.5);
}
.add_class_options li .type {
  font-size: 0.9rem;
  width: 65%;
  font-weight: 500;
  padding-right: 10px;
}
.add_class_options li .input {
  width: 20%;
}
.add_class_options li .input .form-control {
  text-align: center;
  padding: 0;
}

.admin-header {
  background-color: #FFF;
  padding: 0.6rem;
}
.admin-header .brand {
  width: 240px;
}
.admin-header .navbar-general {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.admin-header .navbar-general li.user-logged-in #dropdownUserLoggedIn {
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: transparent;
}
.admin-header .navbar-general li.user-logged-in #dropdownUserLoggedIn::after {
  display: none;
}
.admin-header .navbar-general li.user-logged-in #dropdownUserLoggedIn .thumb img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.admin-header .navbar-general li.user-logged-in #dropdownUserLoggedIn .info {
  padding-left: 0.6rem;
  line-height: 1rem;
}
.admin-header .navbar-general li.user-logged-in #dropdownUserLoggedIn .info .user_name {
  display: block;
  font-size: 1rem;
  color: #0095DF;
  font-weight: 600;
}
.admin-header .navbar-general li.user-logged-in #dropdownUserLoggedIn .info .role {
  font-size: 0.8rem;
}
.admin-header .navbar-general li.user-logged-in .dropdown-menu {
  border: none;
  top: 12px !important;
  border-radius: 0;
}
.admin-header .navbar-general li.user-logged-in .dropdown-menu li .dropdown-item {
  display: flex;
  align-items: center;
}
.admin-header .navbar-general li.user-logged-in .dropdown-menu li .dropdown-item i {
  margin-right: 0.6rem;
}
.admin-header .navbar-general li.admin-toggle-side-menu {
  padding: 0 1rem;
}
.admin-header .navbar-general li.admin-toggle-side-menu a {
  color: #0095DF;
  font-size: 1.7rem;
}
.admin-header .navbar-general li .nav .nav-item .nav-link {
  color: #003970;
}
.admin-header .navbar-general li .nav .nav-item .nav-link:hover {
  color: #0095DF;
}

.navbar-module {
  background-color: #003970;
  padding: 0;
}
.navbar-module .navbar-nav .nav-item .nav-link {
  color: #FFF;
  padding: 1rem 1.3rem;
  font-size: 0.9rem;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.navbar-module .navbar-nav .nav-item .nav-link:hover, .navbar-module .navbar-nav .nav-item .nav-link:focus {
  background-color: #0095DF;
}
.navbar-module .navbar-nav .nav-item:last-child .nav-link {
  border-right: none;
}

.admin-sidebar .size-3 {
  width: 300px;
}
.admin-sidebar .offcanvas-main-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.admin-sidebar .offcanvas-main-menu li a {
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  border-radius: 0.8rem;
}
.admin-sidebar .offcanvas-main-menu li a span {
  color: #000;
}
.admin-sidebar .offcanvas-main-menu li a:hover {
  background-color: rgba(0, 149, 223, 0.1);
}
.admin-sidebar .offcanvas-main-menu li a:hover span {
  color: #0095DF;
}
.admin-sidebar .offcanvas-main-menu li a img {
  width: 26px;
  margin-right: 1rem;
}

.admin-footer {
  background-color: #F8F8F8;
  background-image: none;
  position: fixed;
  padding: 0.4rem 0;
  bottom: 0;
  width: 100%;
  font-size: 0.8rem;
}
.admin-footer .copyright {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  margin-top: 1rem;
}
.admin-footer .copyright .row {
  align-items: center;
}
.admin-footer .copyright .footer-social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.admin-footer .copyright .footer-social li {
  margin-left: 1rem;
  font-size: 1.5rem;
}
.admin-footer .copyright .footer-social li a {
  color: rgba(0, 0, 0, 0.5);
}

.upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: rgb(45, 45, 45);
  padding: 40px;
  flex-direction: column;
}
.upload-box label {
  text-align: center;
}
.upload-box h4 {
  font-weight: 500;
  font-size: 16px;
}
.upload-box p {
  font-size: 12px;
  margin-bottom: 20px;
  color: rgb(181, 168, 168);
}
.upload-box input {
  position: absolute;
  opacity: 0;
  left: 0;
}

.upload-img-card {
  border-radius: 12px;
  border: 2px dashed #003970;
  padding: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
}
.upload-img-card .upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(45, 45, 45);
  padding: 0;
  flex-direction: column;
}
.upload-img-card .upload-box input {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.upload-img-card .upload-box .thumnail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 95%;
}
.upload-img-card .upload-box .thumnail svg {
  width: 20px;
  margin-bottom: 10px;
}
.upload-img-card .upload-box .thumnail p {
  margin: 0;
}
.upload-img-card .delete {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 15px;
}
.upload-img-card .delete svg {
  stroke: #003970;
  width: 20px;
}
.upload-img-card.cursor_none {
  cursor: not-allowed;
}
.upload-img-card.cursor_none .thumnail,
.upload-img-card.cursor_none p {
  cursor: not-allowed;
}

@media screen and (max-width: 767px) {
  .admin-header .navbar-general li.user-logged-in #dropdownUserLoggedIn .info {
    display: none;
  }
  .call-to-action {
    padding: 2.75rem;
    text-align: center;
  }
  .call-to-action .cta-button {
    text-align: center;
  }
}
.admin-page-head {
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.admin-page-head .page-title {
  font-weight: 800;
  font-size: 1.8rem;
  color: #0095DF;
  line-height: 35px;
}
.admin-page-head .breadcrumb {
  margin: 0;
}
.admin-page-head .breadcrumb a {
  color: #293333;
}
.admin-page-head .page-right-actions {
  display: flex;
  justify-content: flex-end;
}
.admin-page-head .page-right-actions .button {
  margin-left: 1rem;
}

@media screen and (max-width: 767px) {
  .admin-page-head .page-right-actions {
    justify-content: center;
    gap: 10px;
    margin-top: 1rem;
  }
  .admin-page-head .page-right-actions .button {
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 0.9rem;
    margin-left: 0;
  }
}

