.age-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding:20px;

    p {
        font-weight: 600;
        font-size: 16px;
        color: #656565;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align:center;

        .img-fluid {
            max-width: 40%;
        }

        h2 {
            font-weight: 700;
            font-size: 24px;
            color: $secondary;
           
            margin: 0px;
        }

        h3 {
            font-weight: 600;
            font-size: 20px;
            padding-top: 5px;
            margin: 0px;
        }
    }
}
