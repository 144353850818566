header {
    width: 100%;
    z-index: 99;

    .logo {
        width: 270px;
        margin-left: 18px;
    }

    .logo-1 {
        display: none;
    }

    .navbar-nav {

        .nav-item {
            .nav-link {
                padding: .5rem 1rem;
                font-size: 14px;
                font-family: $font-primary;
                font-weight: 500;
                transition: 0.3s all;
                position: relative;

                &:hover {
                    color: $black;
                }
            }
        }
    }


    form {
        .nav-link {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        span {

            i {
                padding-right: 5px;
                font-size: 1.2rem;
            }
        }
    }
}

nav.navbar.navbar-expand-lg.bg-white {
    padding: 0;
}

.sticky {
    position: fixed;
    top: 0;
    background-color: white;
    box-shadow: 10px -27px 26px 8px black;
}

button.navbar-toggler {
    display: none;
}

header.scrolled {
    position: sticky;
    top: 0;
    background: white;
    box-shadow: 0px 0px 16px rgba(0, 124, 195, 0.25);
}

@media screen and (max-width: 1332px) {
    header {

        .logo {
            display: none;

        }

        .logo-1 {
            width: 60px;
            display: block;
        }


        .navbar-nav {

            .nav-item {
                .nav-link {
                    padding: 0.5rem 0.6rem;
                    font-size: 13.5px;

                }
            }
        }

        form {
            .nav-link {
                font-size: 12px;
                padding: 7px 6px;
            }


        }
    }
}

@media screen and (max-width: 400px) {
    header {
        .logo {
            width: 250px;
            margin-left: 0;

        }
    }
}