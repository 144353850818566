.school {
    align-items: flex-end;
}

.photo-card {
    border-radius: 7px;
    background-color: $liteblack;
    width: 25.25rem;

    img {
        width: 25.25rem;
        height: 230px;
    }

    .card-body {
        padding: 30px;
        min-height: 126px;

        .card-text {
            font-family: $font-teacher;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            text-align: center;
            color: black;
        }
    }
}

.photo-detail-card {
    border-radius: 7px;
    background-color: white;
    width: 100%;

    img {
        width: 100%;
        height: 230px;
    }

    .card-body {
        padding: 30px;
        min-height: 126px;

        .card-text {
            font-family: $font-teacher;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            text-align: center;
            color: black;
        }
    }
}

@media screen and (max-width : 767px) {
    .photo-card {
        margin-bottom: 17px;
        width: 22.25rem;

        img {
            width: 100%;
        }

        .card-body {
            padding: 30px;
            min-height: 126px;

            .card-text {
                font-weight: 300;
                font-size: 15px;
                line-height: 22px;
                text-align: center;
            }
        }
    }

    .school {
        align-items: flex-end;
    }
}